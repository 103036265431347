<template>
  <div>
    <div class="mb-0 flex justify-center" style="border-bottom: 1px solid gainsboro">
      <router-link to="/">
        <img src="/melior_dk.svg" class="mb-4 h-8" alt="" />
      </router-link>
    </div>
    <div class="">
      <form id="login-form" @submit.prevent="submitForm" role="form">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-5 col-md-offset-3">
              <div class="row mt-topp">
                <div class="col-lg-12">
                  <div v-if="authStep == 1">
                    <div class="mb-5">
                      <div>
                        <h4 class="text-dark text-4xl font-weight-bold">Welcome to Melior!</h4>
                      </div>
                      <p class="text-dark">Create an account to get started</p>
                    </div>

                    <div>
                      <form @submit.prevent="submitAuthInformation()">
                        <div class="form-group">
                          <label class="text-dark" to="email">Email address</label>
                          <input v-model="signupPayload.email" required type="email" name="email" tabindex="1" id="email"
                            class="py-2 px-3 border rounded w-full hover:text-primary focus:text-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2" />
                        </div>

                        <div class="form-group">
                          <label class="text-dark" to="password">Password</label>
                          <input type="password" required v-model="signupPayload.password" name="password" id="password"
                            tabindex="2"
                            class="py-2 px-3 border rounded w-full hover:text-primary focus:text-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2" />

                          <div v-if="signupPayload.password">
                            <vs-progress :height="3" :percent="getProgress"
                              :color="getProgressColor">primary</vs-progress>
                            <p class="text-sm" :class="{
                              'text-success': getProgress >= 100,
                              'text-danger': getProgress < 100,
                            }">
                              {{ getProgressInfo }}
                            </p>
                          </div>
                        </div>

                        <div class="form-group text-center mt-5">
                          <button type="submit"
                            class="bg-primary text-white py-3 px-4 mr-3 hover:bg-primary rounded hover:text-white focus:text-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 w-full">
                            Sign up with email
                            <BtnLoading v-if="loading" class="d-inline-block" height="18" />
                          </button>
                        </div>
                      </form>

                      <p class="text-dark mb-5">
                        Already have an account?
                        <router-link to="/login" class="text-primary">Sign in</router-link>
                      </p>

                      <p class="text-dark" style="font-size: 11px">
                        By creating an account, I agree to the
                        <a href="https://melior.africa/terms" target="_blank" class="text-primary">Terms & Condition</a>
                        &
                        <a href="https://melior.africa/privacy" target="_blank" class="text-primary">Privacy Policy</a>
                      </p>
                    </div>
                  </div>


                  <div v-else-if="authStep == 2">
                    <form @submit.prevent="submitArtistInformation()">
                      <div class="mb-5">
                        <div class="row align-items-center">
                          <div class="col-8">
                            <h4 class="text-dark text-3xl font-weight-bold">
                              Setup Artist Profile
                            </h4>
                          </div>
                          <div class="col-4">
                            <div class="flex justify-end">
                              <button @click="authStep = 1" type="button"
                                class="flex items-center text-sm space-x-2 text-primary bg-white border rounded-md px-3 py-2 focus:text-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2">
                                <ArrowLeftIcon size="20" />
                                <span>Back</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="form-group">
                        <label class="text-dark" to="email">Artist Name</label>
                        <input v-model="signupPayload.artist_name" required name="email" tabindex="1" id="email"
                          placeholder="e.g Simisola"
                          class="py-2 px-3 border rounded w-full hover:text-primary focus:text-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2" />
                        <div v-if="formErrors">
                          <vs-alert class="mb-1" v-for="(error, index) in formErrors.artist_name" :key="index">
                            {{ error }}
                          </vs-alert>
                        </div>
                      </div>

                      <hr class="my-4" />
                      <p class="text-dark text-sm">
                        Do you already have music release on Spotify with this
                        artist
                      </p>

                      <div class="d-flex my-3">
                        <vs-radio v-model="signupPayload.is_spotify" vs-name="isSpotify" vs-value="yes" class="mr-3">
                          <span class="text-dark">Yes</span>
                        </vs-radio>
                        <vs-radio v-model="signupPayload.is_spotify" vs-name="isSpotify" vs-value="no" class="mr-3">
                          <span class="text-dark">No</span>
                        </vs-radio>
                      </div>

                      <div class="my-2">
                        <vs-alert active="true">
                          <ul class="mx-3 list-disc">
                            <li>
                              <span>
                                If you already have music released, please use the
                                same artist name.
                              </span>
                            </li>
                            <li>
                              <span>
                                If you don't have music released, please use a
                                unique and easy to search name (check if your
                                artist name is available).
                              </span>
                            </li>
                            <li>
                              <span>
                                Please don't use someone else's artist name
                              </span>
                            </li>
                            <li>
                              <span>
                                Once you've signed up, you can't change artist
                                name, please be thoughtful about it.
                              </span>
                            </li>
                            <li>
                              <span> No emojis allowed </span>
                            </li>
                          </ul>
                        </vs-alert>
                      </div>

                      <div class="form-group text-center mt-5">
                        <button type="submit"
                          class="bg-primary text-white py-3 px-4 mr-3 hover:bg-primary rounded hover:text-white focus:text-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 w-full">
                          Continue
                        </button>
                      </div>
                    </form>

                  </div>

                  <div v-else-if="authStep == 3">
                    <div class="mb-5">
                      <div class="row align-items-center">
                        <div class="col-8">
                          <h4 class="text-dark text-3xl font-weight-bold">
                            Setup Spotify Profile
                          </h4>
                        </div>
                        <div class="col-4">
                          <div class="flex justify-end">
                            <button @click="authStep = 2" type="button"
                              class="flex items-center text-sm space-x-2 text-primary bg-white border rounded-md px-3 py-2 focus:text-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2">
                              <ArrowLeftIcon size="20" />
                              <span>Back</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- spotifyLoading -->
                    <div v-if="spotifyDataLoading">
                      <SectionLoading text="Spotify data loading..." />
                    </div>

                    <div v-else>
                      <p class="text-dark mb-3">
                        Please select the artist belew that is yours
                      </p>
                      <div class="spotifyList">
                        <div class="spotifyItem" @click="setSpotifyData(item.id)" v-for="(item, index) in spotifyList"
                          :key="index">
                          <div class="spotifyItem__image">
                            <vs-avatar v-if="item.images.length > 0" :src="item.images[0].url" size="60px" />
                            <vs-avatar v-else :text="item.name" size="60px" color="primary" />
                          </div>
                          <div class="spotifyItem__info">
                            <div class="spotifyItem__name">
                              {{ item.name }}
                            </div>
                            <div class="spotifyItem__followers">
                              {{ item.followers && item.followers.total | number("0,0") }} Followers
                            </div>
                          </div>
                        </div>

                        <div class="spotifyItem" @click="authStep = 4">
                          <div class="spotifyItem__image">
                            <vs-avatar text="+" size="60px" color="primary" />
                          </div>
                          <div class="spotifyItem__info">
                            <div class="spotifyItem__name">
                              This artist is not on spotify
                            </div>
                            <div class="spotifyItem__followers">
                              Create new artist page
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-else-if="authStep == 4">
                    <div class="mb-5">
                      <div class="row align-items-center">
                        <div class="col-8">
                          <h4 class="text-dark text-3xl font-weight-bold">
                            Complete Sign up
                          </h4>
                        </div>
                        <div class="col-4">
                          <div class="flex justify-end">
                            <button @click="profileBack()" type="button"
                              class="flex items-center text-sm space-x-2 text-primary bg-white border rounded-md px-3 py-2 focus:text-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2">
                              <ArrowLeftIcon size="20" />
                              <span>Back</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <form @submit.prevent="completeSignUp()">
                      <div class="form-group">
                        <label class="text-dark" to="full_name">Full name</label>
                        <input required name="full_name" class="py-2 px-3 border rounded w-full hover:text-primary focus:text-primary focus:outline-none
                      focus:ring-2 focus:ring-primary focus:ring-offset-2" v-model="signupPayload.name" />
                        <div v-if="formErrors">
                          <vs-alert class="mb-1" v-for="(error, index) in formErrors.name" :key="index">
                            {{ error }}
                          </vs-alert>
                        </div>
                      </div>

                      <div class="form-group">
                        <label class="text-dark" to="artist_name">Artist name</label>
                        <input required v-model="signupPayload.artist_name" disabled name="artist_name" class="py-2 px-3 border rounded w-full hover:text-primary focus:text-primary focus:outline-none
                      focus:ring-2 focus:ring-primary focus:ring-offset-2" />
                        <div v-if="formErrors">
                          <vs-alert class="mb-1" v-for="(error, index) in formErrors.artist_name" :key="index">
                            {{ error }}
                          </vs-alert>
                        </div>
                      </div>

                      <div class="mb-4">
                        <label class="text-sm block text-gray-700 font-medium mb-1">Country</label>
                        <vs-select class="s-select" v-model="signupPayload.country" autocomplete>
                          <vs-select-item :key="index" :value="item" :text="item.name"
                            v-for="(item, index) in allCountries" />
                        </vs-select>
                      </div>

                      <div class="form-group">
                        <label class="text-dark" to="phone">Phone number</label>
                        <input name="phone" required v-model="signupPayload.phone" class="py-2 px-3 border rounded w-full hover:text-primary focus:text-primary focus:outline-none
                      focus:ring-2 focus:ring-primary focus:ring-offset-2" />
                        <div v-if="formErrors">
                          <vs-alert class="mb-1" v-for="(error, index) in formErrors.phone" :key="index">
                            {{ error }}
                          </vs-alert>
                        </div>
                      </div>

                      <div class="form-group text-center mt-5">
                        <button :disabled="signuploading" type="submit"
                          class="bg-primary text-white py-3 px-4 mr-3 hover:bg-primary rounded hover:text-white focus:text-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 w-full">
                          Complete
                          <BtnLoading v-if="signuploading" class="d-inline-block" height="18" />
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import BtnLoading from "@/components/BtnLoading.vue";
import { ArrowLeftIcon } from "vue-feather-icons";
import SectionLoading from "@/components/SectionLoading";
import countries from "@/utils/countries";
// import GoogleLogin from "vue-google-login";

export default {
  components: { BtnLoading, ArrowLeftIcon, SectionLoading },
  data() {
    return {
      email: "",
      loading: false,
      signuploading: false,
      authStep: 1,
      formErrors: {},
      spotifyDataLoading: false,
      signupPayload: {
        email: "",
        password: "",
        artist_name: "",
        spotify_id: "",
        name: "",
        phone: "",
        country_code: "",
        is_spotify: "",
        country: "",
      },
      spotifyList: [],
    };
  },
  computed: {
    allCountries() {
      return countries
    },
    getProgressColor() {
      if (this.getProgress <= 20) {
        return "danger";
      } else if (this.getProgress <= 40) {
        return "warning";
      } else if (this.getProgress >= 100) {
        return "success";
      }
    },
    getProgress() {
      let progress = 0;

      // at least one number
      if (/\d/.test(this.signupPayload.password)) {
        progress += 20;
      }

      // at least one capital letter
      if (/(.*[A-Z].*)/.test(this.signupPayload.password)) {
        progress += 20;
      }

      // at menons a lowercase
      if (/(.*[a-z].*)/.test(this.signupPayload.password)) {
        progress += 20;
      }

      // more than 5 digits
      if (this.signupPayload.password.length >= 6) {
        progress += 20;
      }

      // at least one special character
      if (/[^A-Za-z0-9]/.test(this.signupPayload.password)) {
        progress += 20;
      }

      return progress;
    },
    getProgressInfo() {
      if (this.getProgress <= 20) {
        return "Enter a password with at least 6 characters";
      } else if (this.getProgress <= 40) {
        return "Enter a password with at least 1 number";
      } else if (this.getProgress <= 60) {
        return "Enter a password with at least 1 number, 1 capital letter, 1 lowercase letter, 1 special character";
      } else if (this.getProgress <= 80) {
        return "Enter a password with at least 1 number, 1 capital letter, 1 lowercase letter, 1 special character";
      } else if (this.getProgress >= 100) {
        return "Password is strong";
      }
    }
  },
  mounted() {
    if (this.$route.query.uuid) {
      this.checkUserRedirect();
    }
  },
  methods: {
    submitAuthInformation() {
      if (this.signupPayload.email && this.signupPayload.password && this.getProgress >= 100) {
        this.authStep = 2;
      } else {
        this.$toast.info(
          "Signup",
          "Please fill in the form correctly",
          this.$toastPosition
        );
      }
    },
    submitArtistInformation() {
      if (this.signupPayload.artist_name) {
        if (this.signupPayload.is_spotify == "yes") {
          this.authStep = 3;
          this.getSpotifyData();
        } else {
          this.authStep = 4;
        }
      } else {
        this.$toast.info(
          "Signup",
          "Please fill in the form correctly",
          this.$toastPosition
        );
      }
    },
    checkUserRedirect() {
      this.$vs.loading();
      const { uuid } = this.$route.query;
      let data = {};
      let payload = {
        data,
        path: `auth/redirect-login?uuid=${uuid}`,
      };
      this.$store
        .dispatch("authRequest", payload)
        .then((resp) => {
          this.$vs.loading.close();
          let { token, user } = resp.data.data;
          token = this.CryptoJS.AES.encrypt(
            token.access_token,
            this.$passPhrase
          ).toString();
          user = this.CryptoJS.AES.encrypt(
            JSON.stringify(user),
            this.$passPhrase
          ).toString();

          localStorage.setItem("token", token);
          localStorage.setItem("user", user);
          this.$toast.success(
            "Login",
            "Login successful.",
            this.$toastPosition
          );

          setTimeout(() => {
            window.location.href = "/upgrade-plan";
          }, 1000);
        })
        .catch((err) => {
          this.$vs.loading.close();
          if (err.response) {
            this.formErrors = err.response.data.payload;
            this.$toast.info(
              "Login",
              err.response.data.message,
              this.$toastPosition
            );
          } else {
            this.$toast.info(
              "Login",
              "Unable to login, check the details and try again",
              this.$toastPosition
            );
          }
        });
    },
    submitForm() {
      this.loading = true;
      let data = {
        email: this.email,
        password: this.password,
      };
      let payload = {
        data,
        path: "/auth/login",
      };
      this.$store
        .dispatch("authRequest", payload)
        .then((resp) => {
          this.loading = false;

          let { token, user } = resp.data.data;
          // let user = resp.data.payload.user;
          token = this.CryptoJS.AES.encrypt(
            token.access_token,
            this.$passPhrase
          ).toString();
          user = this.CryptoJS.AES.encrypt(
            JSON.stringify(user),
            this.$passPhrase
          ).toString();

          localStorage.setItem("token", token);
          localStorage.setItem("user", user);
          this.$toast.success(
            "Login",
            "Login successful.",
            this.$toastPosition
          );

          setTimeout(() => {
            window.location.href = "/";
          }, 1000);
        })
        .catch((err) => {
          this.loading = false;
          if (err.response) {
            this.formErrors = err.response.data.payload;
            this.$toast.info(
              "Login",
              err.response.data.message,
              this.$toastPosition
            );
          } else {
            this.$toast.info(
              "Login",
              "Unable to login, check the details and try again",
              this.$toastPosition
            );
          }
        });
    },
    setSpotifyData(id) {
      this.signupPayload.spotify_id = id;
      this.authStep = 4;
    },
    getSpotifyData() {
      this.spotifyDataLoading = true;

      let payload = {
        data: {
          name: this.signupPayload.artist_name,
        },
        path: "/auth/resolve-spotify",
      };
      this.$store
        .dispatch("authRequest", payload)
        .then((resp) => {
          this.spotifyDataLoading = false;
          this.spotifyList = resp.data.data?.artists?.items?.slice(0, 5) || [];
        })
        .catch((err) => {
          this.spotifyDataLoading = false;
          if (err.response) {
            this.formErrors = err.response.data.payload;
            this.$toast.info(
              "Spotify Data",
              err.response.data.message,
              this.$toastPosition
            );
          } else {
            this.$toast.info(
              "Spotify Data",
              "Unable to get spotify data, check the details and try again",
              this.$toastPosition
            );
          }
        });
    },
    profileBack() {
      if (this.signupPayload.is_spotify == "yes") {
        this.authStep = 3;
        this.getSpotifyData();
      } else {
        this.authStep = 2;
      }
    },
    completeSignUp() {
      this.signuploading = true;
      this.$vs.loading();

      let data = {
        name: this.signupPayload.name,
        email: this.signupPayload.email,
        password: this.signupPayload.password,
        password_confirmation: this.signupPayload.password,
        country: this.signupPayload.country?.name,
        country_code: this.signupPayload.country?.code,
        phone: this.signupPayload.phone,
        artist_name: this.signupPayload.artist_name,
        spotify_id: this.signupPayload.spotify_id,
      };

      let payload = {
        data,
        path: "/auth/register",
      };
      this.$store
        .dispatch("authRequest", payload)
        .then((resp) => {
          this.loading = false;

          let { token, user } = resp.data.data;

          token = this.CryptoJS.AES.encrypt(
            token.access_token,
            this.$passPhrase
          ).toString();
          user = this.CryptoJS.AES.encrypt(
            JSON.stringify(user),
            this.$passPhrase
          ).toString();

          localStorage.setItem("token", token);
          localStorage.setItem("user", user);
          this.$toast.success(
            "Sign Up",
            "Sign Up successful.",
            this.$toastPosition
          );

          setTimeout(() => {
            window.location.href = "/";
          }, 1000);
        })
        .catch((err) => {
          this.loading = false;
          if (err.response) {
            this.formErrors = err.response.data.payload;
            this.$toast.info(
              "Sign Up",
              err.response.data.message,
              this.$toastPosition
            );
          } else {
            this.$toast.info(
              "Sign Up",
              "Unable to sign up, check the details and try again",
              this.$toastPosition
            );
          }
        }).finally(() => {
          this.signuploading = false;
          this.$vs.loading.close();
        });
    }
  },
};
</script>
<style lang="scss" scoped>
.btn-site-white {
  background-color: white !important;
  color: black;
  box-shadow: 0px 0px;
  border: 1px solid black;
}

.spotifyList {
  background: #f7f7f7;
  border-radius: 12px;
  padding: 12px;

  .spotifyItem {
    display: flex;
    align-items: center;
    cursor: pointer;
    border-bottom: 1px solid gainsboro;
    padding: 10px 0px;

    &:hover {
      background: #f0f0f0;
    }

    &:last-child {
      border-bottom: none;
    }

    &__image {
      margin-right: 10px;
    }

    &__name {
      font-weight: bold;
      color: black;
    }

    &__followers {
      color: black;
      font-size: 14px;
    }
  }
}
</style>
